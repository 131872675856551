import  {useState} from 'react';
import {
  Container,
  Box,
  Step,
  StepLabel,
  Stepper,
  Button,
  Typography,
  Grid
} from '@mui/material';
import $ from 'jquery';
import Loading from "../../Component/loading";

import Registration from './Registration';
import CadastroMulheres from './CadastroMulheres';
import { useApp } from '../../Contexts/AppProvider';
import axios from 'axios';

const steps = [
  {
    label: 'Bem-vinda',
    description: <div style={{paddingBottom: 100}}>
      Bem-vinda ao Programa de Microcrédito Sigma Dona de Mim!
      <br /><br />
      Agora começa sua jornada de empreendedorismo e conquistas.
      <br /><br />


      Seguiremos juntos, lado a lado em busca de seus sonhos.
      <br /><br />


      Serão alguns passos importantes para ajudarmos no seu sucesso.
      <br />
      <br />
      Passo 1:
      <br />
      Faremos algumas perguntas para te conhecer melhor e te ajudar a planejar a melhor forma de usar o dinheiro que você receberá.
      <br />
      <br />
      Passo 2:
      <br />
      Será gerado um Cupom para o aplicativo n2.
      <br /><br />
      Baixo o aplicativo, e ative o cupom.
      <br />
      Se preferir, vá para o Passo 3 que lá ajudaremos você com o Passo 2.
      <br />
      <br />
      Passo 3:
      <br />
      Após gerar seu Cupom (Passo 2), você receberá em até 24hrs um link pelo WhatsApp para marcar uma conversa com a sua Orientadora ou Orientador Financeiro.
      <br />
      Agende essa conversa por videoconferência com ela para montarmos juntos seu plano de ação e explicarmos os próximos passos!
      <br />
      Essa pessoa foi selecionada para estar ao seu lado durante toda a jornada de construção do seu negócio e uso do dinheiro.
      <br />
      Pelo nosso aplicativo, você poderá falar com ela todos os dias!!!
      <br />
      Legal né!
      <br />
      <br />
      Passo 4:
      <br />
      Após a videoconferência você receberá por e-mail o seu Contrato de Crédito com o Banco Pérola.
      <br />
      Você deverá assinar o contrato.
      <br />
      Após a assinatura do contrato, o Banco Pérola fará um Pix do valor do crédito para sua conta.
      <br />
      Lembre-se, é um crédito e você deverá pagar as parcelas direitinho!
      <br />
      Este dinheiro voltará para o fundo para ajudar outras mulheres ou você mesma, caso precise de mais crédito.
      <br />

      <br />
      Estaremos ao seu lado para pensar juntos em como organizar o dinheiro para dar para fazer tudo!!!
      <br />
      <br />

      Marcaremos com você, durante todo o período do seu contrato de crédito, várias outras reuniões em grupo e individuais para estarmos juntas na construção do seu negócio! A participação nestas reuniões é OBRIGATÓRIA!
      <br /><br />


      Conte com a gente!
      <br /><br />


      Fundo Dona de Mim – Sigma Lithium
    </div>,
  },
  {
    label: 'Planejamento n²',
    description: <div>Como parte do processo para receber seu crédito, devemos planejar como vamos usar esse dinheiro.<br />
    Preencha os campos abaixo para nossos Orientadores Financeiros ajudarem você no planejamento do seu negócio.</div>,
    page: Registration
  },
  {
    label: 'Cupom',
    // page: CadastroMulheres
  }
];

export default function App() {
  const {setAlert} = useApp()
  let onDataRegister: any = {
    name: '',
    phone: '', 
    email: '',
    cpf: '',
    rg: '',
    birthDate: null,
    list: []
  };
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState('');
  const [voucher, setVoucher] = useState('');

  const handleNext = async () => {
    try {
      let disabled = false;
      $('#formRegistration :input').each(function( index ) {
        if(($(this).val() === "" || $(this).val() === "-1") && $(this)[0].tagName !== 'BUTTON'){
          $(this).parent('div').css("background", "#ff00002b");
          disabled = true;
        }
      });


      setTimeout(async () => {
        if(!disabled) {
          if(onDataRegister.email !== '' && onDataRegister.phone !== '' && onDataRegister.name !== ''){
            setIsLoading('Carregando...');
            const {data} = await axios.post('https://api.n2app.info/voucher/invitation-voucher', {
              partnerId: 126,
              firstName: onDataRegister.name,
              lastName: '-',
              email: onDataRegister.email,
              phone: onDataRegister.phone.replace(/\D/g, ""),
              utmSource: null,
              tid: null
            },{
              headers: {
                'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkxODc0MDUyLCJqdGkiOiJkMjViNWVkZGQyZGQ0ZDA4YTQ0MjRhZGNiM2Q1YmIxMSIsInVzZXJfaWQiOjcyNTJ9.KsvsStdVaTMVxM3ZmbJkqmokQXLZhnocPhyxBfipPKw`,
                'Content-Type': 'application/json',
              },
            });

            setIsLoading('Gerando cupom...');
            if(data?.error){
              if(data?.error){
                setAlert({message: data?.msg, type: 'warning'});
                setTimeout(() => {
                  setAlert({message: '', type: 'warning'});
                }, 6000);
              }
              setIsLoading('');
              return;
            }else{
              await axios.post("https://n2-dona-de-mim-api.n2app.info/register", onDataRegister);
              setVoucher(data?.invitation_code);
              setIsLoading('');
            }
          }
    
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }else{
          setAlert({message: 'Por favor preencha todos os campos necessários', type: 'warning'});
          setTimeout(() => {
            setAlert({message: '', type: 'warning'});
          }, 6000);
        }
      }, 1000);
    } catch (error: any) {
      if(error?.response?.data?.error){
        setAlert({message: error?.response?.data?.msg, type: 'warning'});
        setTimeout(() => {
          setAlert({message: '', type: 'warning'});
        }, 6000);
      }
      setIsLoading('');
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getDataRegister = (data: any) => {
    $('#formRegistration :input').each(function( index ) {
      $(this).parent('div').attr('style', '');
    });
    onDataRegister = data;
  }

  const Page = steps[activeStep]?.page;

  return (
    <>
      <Box sx={{bgcolor: '#f7d800', textAlign: 'center'}}>
        <Grid container spacing={0}>
        <Grid item xl={3} lg={3} md={3} xs={12}>
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12} sx={{lineHeight: 0}}>
            <img style={{position: 'relative', width: '100%'}} src='/assets/img/logos.png' />
          </Grid>
          <Grid sx={{bgcolor: '#f7d800'}} item xl={3} lg={3} md={3} xs={12}>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid sx={{bgcolor: '#6940b9'}} item xl={3} lg={3} md={3} xs={12}>
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12} sx={{lineHeight: 0}}>
            <img style={{position: 'relative', width: '100%'}} src='/assets/img/slogan.png' />
          </Grid>
          <Grid sx={{bgcolor: '#f7d800'}} item xl={3} lg={3} md={3} xs={12}>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <Container>
              <Box sx={{ width: '100%', pt: 5, pb: 5 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              <Box sx={styles.content}>
                <Box>
                  <Typography variant='h4'>{steps[activeStep]?.label}</Typography>
                  {steps[activeStep]?.description}
                  {Page && <Page onData={getDataRegister} voucher={voucher} dataRegister={onDataRegister} />}
                  {activeStep === 2 && (
                    <div style={{ paddingBottom: 40}}>
                      <div style={{paddingTop: 20, display: 'grid', textAlign: 'center'}}>
                        <div>
                      Parabéns!<br />
                      Você concluiu a primeira parte do processo.<br />
                      Agora vamos agendar uma videoconferência com seu orientador financeiro.<br />
                      Você receberá um Whatsapp com sugestões de datas em até 24hrs para fazer seu agendamento.
                      <br /><br />
                      Não esqueça de anotar seu cupom!<br />
                      Se quiser, já pode Baixe o app, ativá-lo! Se preferir, na videoconferência vamos ajudá-la com isso.
                      <br /><br />
                    </div>
                        <div style={{textAlign: 'center', display: 'unset', padding: 20, borderWidth: 2, borderColor: 'lightgray', borderStyle: 'dashed', fontWeight: 700, fontSize: 30, justifySelf: 'center'}}>
                          {voucher || 'carregando...'}
                          <img onClick={() => navigator.clipboard.writeText(`${voucher}`)} src="https://convite.n2app.info/assets/images/copy.png" style={{marginLeft: 10, marginTop: -4, height: 25, width: 25, cursor: 'pointer'}} />
                        </div>
                        <img src="https://convite.n2app.info/assets/images/qrcode.png" alt="Baixe o aplicativo da n2 pela Google Play Store" style={{marginTop: 20, display: 'unset', justifySelf: 'center'}} />
                        <div style={{paddingTop: 30}}>
                          <a href="https://play.google.com/store/apps/details?id=info.n2app" target="_blank" rel="noreferrer">
                            <img src="https://convite.n2app.info/assets/images/google_play_2.png" alt="Baixe o aplicativo da n2 pela Google Play Store" />
                          </a>
                          <a href="https://apps.apple.com/br/app/id1499731786" target="_blank" rel="noreferrer">
                            <img style={{marginTop: 3}} src="https://convite.n2app.info/assets/images/app_store_2.jpg" alt="Baixe o aplicativo da n2 pela Apple Store" />
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </Box>
        
                {activeStep < steps.length-1 && (
                  <Box sx={{display: 'flex', justifyContent: 'space-between' }}>
                    {activeStep !== 0 && (
                      <Button variant="contained" onClick={handleBack}>
                        Anterior
                      </Button>
                    )}

                    <Button id='btnNext' variant="contained" onClick={handleNext}>
                      Próximo
                    </Button>  
                  </Box>
                )}
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Container>
          <Grid container spacing={0}>
            <Grid item xl={6} lg={6} md={6} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant='caption' color='#009fff' sx={{display: 'flex', justifyContent: 'center'}}>Copyright © 2022. n², Tecnologia e Informação LTDA. Todos os direitos reservados.</Typography>
            </Grid>

            <Grid item xl={6} lg={6} md={6} xs={12} sx={{textAlign: 'right'}}>
              <img style={{position: 'relative', height: 60}} src='/assets/img/powered.png' />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {isLoading && <Loading text={isLoading} />}
    </>
  );
}

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    p: 4,
    mb: 3,
    border: 'solid #eeeeee 0.6px',
    borderRadius: 7
  },
}