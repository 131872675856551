import React from 'react';
import 'moment/locale/pt-br';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import ThemeProvider from './Contexts/ThemeProvider';
import AppProvider from './Contexts/AppProvider';

import Init from './Pages/Init';

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider>
          <AppProvider>
            <Init />
          </AppProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
