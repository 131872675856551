import React, {createContext, useContext, useMemo, useState} from 'react';
import {Alert, Snackbar} from '@mui/material';

interface IAlert {
  message: string,
  type: 'error' | 'warning' | 'info' | 'success',
}

interface IContext {
  isLoadingApp: boolean,
  setIsLoadingApp(isLoadingApp: boolean): void,
  isOpenDrawer: boolean, 
  setIsOpenDrawer(isOpenDrawer: boolean): void,
  toggleDrawer(): void,
  alert: IAlert, 
  setAlert(value: IAlert): void
}

const Context = createContext({} as IContext);

export default function AppProvider({children}: {children: React.ReactNode}) {
  const [isLoadingApp, setIsLoadingApp] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [alert, setAlert] = useState<IAlert>({message: '', type: 'success'});

  const toggleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
  }

  const value = useMemo(() => ({
    isLoadingApp, 
    setIsLoadingApp,
    isOpenDrawer,
    setIsOpenDrawer,
    toggleDrawer,
    alert, 
    setAlert
  }), [isLoadingApp, isOpenDrawer, alert]);

  return (
    <Context.Provider value={value}> 
      {children}
      <Snackbar anchorOrigin={{vertical: 'top',
          horizontal: 'center'}} open={!!alert?.message}>
        <Alert severity={alert?.type} sx={{ width: '100%' }}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Context.Provider>
  );
}

export function useApp() {
  const context: IContext = useContext(Context);
  return context;
}