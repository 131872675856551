import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, InputLabel, MenuItem, Select, Chip, useTheme, useMediaQuery, Typography, Link } from '@mui/material';

interface IQuestion {
  index: number,
  question: string,
  answer: string | number
}

interface IData {
  name: string,
  phone: string,
  email: string,
  cpf: string,
  rg: string,
  birthDate: any,
  list: IQuestion[]
}

export default function Registration({onData, dataRegister}: {onData(values: IData): any, dataRegister: IData, voucher: any}) {
  const theme = useTheme();
  const [data, setData] = useState<IData>(dataRegister);

  const isNoMobile = useMediaQuery(theme.breakpoints.up("md"));

  const saveInList = (questions: IQuestion[]) => {
    let newList = [...data.list];
    questions?.map(({index, question, answer}: IQuestion) => {
      if(data?.list?.some((q: IQuestion) => q.index === index)){
        newList = data?.list?.map((q: IQuestion) => {
          if(q.index === index) return {question, answer, index};
          return q;
        });
      }else{
        newList.push({question, answer, index});
      }
    })
    setData({...data, list: newList})
  }

  const deleteInList = (arrayIndex: number[]) => {
    let newList = data?.list?.filter(({index}: {index: number}) => {
      return !arrayIndex.some(i => i === index);
    });
    setData({...data, list: newList})
  }

  const getData = (index: number): IQuestion | undefined => {
    return data?.list?.find((q: IQuestion) => q.index === index);
  }


  onData(data)

  return (
    <Box
      id='formRegistration'
      component="form"
      sx={{
        '& .MuiTextField-root': { mt: 1.5, mb: 1.5 },
        mt: 2,
        mb: 5
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={4}>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Qual é o seu nome?"
            value={data?.name}
            onChange={({target: {value}}) => setData({...data, name: value})}
          />
          <br />

          {isNoMobile ? (
            <DesktopDatePicker
            label={`Data de nascimento?`}
              inputFormat="DD/MM/YYYY"
              value={data?.birthDate}
              onChange={(value) => setData({...data, birthDate: value})}
              renderInput={(params) => <TextField required {...params} />}
            />
          ) : (
            <MobileDatePicker
              label="Data de nascimento?"
              inputFormat="DD/MM/YYYY"
              value={data?.birthDate}
              onChange={(value) => setData({...data, birthDate: value})}
              renderInput={(params) => <TextField required {...params} />}
            />
          )}
          <br />

          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Qual é o seu RG?"
            placeholder=''
            value={data?.rg}
            onChange={({target: {value}}) => setData({...data, rg: value.replace(/\D/g, "")})}
          />
          <br />

          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Qual é o seu CPF?"
            placeholder=''
            value={data?.cpf}
            onChange={({target: {value}}) => setData({...data, cpf: value.replace(/\D/g, "")})}
          />
          <br />

          <TextField
            required
            fullWidth
            id="outlined-required"
            label="Digite aqui seu telefone"
            placeholder={''}
            value={data?.phone}
            onChange={({target: {value}}) => setData({...data, phone: value.replace(/\D/g, "")})}
          />
           
          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Digite seu e-mail?"
            type='email'
            value={data?.email}
            onChange={({target: {value}}) => setData({...data, email: value})}
          />
          


          {/* list */}

          <FormControl sx={{mt: 3}} fullWidth>
            <InputLabel id="demo-simple-select-label">Qual é o seu estado civil?</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Qual é o seu estado civil?"
              value={getData(0)?.answer || '-1'}
              onChange={({target: {value}}) => saveInList([{
                index: 0, 
                question: 'Qual é o seu estado civil?', 
                answer: value
              }])}
            >
              <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
              <MenuItem value={'Solteira'}>Solteira</MenuItem>
              <MenuItem value={'Casada'}>Casada</MenuItem>
              <MenuItem value={'Viúva'}>Viúva</MenuItem>
              <MenuItem value={'Divorciada'}>Divorciada</MenuItem>
              <MenuItem value={'Juntada'}>Juntada</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{mt: 3}} fullWidth component="fieldset" variant="standard">
            <FormLabel component="legend">Você tem dependentes? (Ex: filhos, pais, etc.)</FormLabel>
            <RadioGroup sx={{alignItems: 'center', display: 'flex'}} row name="row-radio-buttons-group">
              <FormControlLabel
                value={false}
                checked={!getData(1)}
                control={<Radio />}
                label="Não"
                onChange={() => deleteInList([1, 2, 3])}
              />
              <FormControlLabel
                value={true}
                checked={!!getData(1)}
                control={<Radio />}
                label="Sim"
                onChange={() => saveInList([{
                  index: 1, 
                  question: 'Você tem dependentes? (Ex: filhos, pais, etc.)', 
                  answer: 0
                }])}
              />
              {!!getData(1) && <Chip label={`total de filhos: ${(parseInt(getData(2)?.answer as string) || 0) + (parseInt(getData(3)?.answer as string) || 0)}`} />}
            </RadioGroup>

            {!!getData(1) && (
              <Box>
                <TextField
                  sx={{mr: 1}}
                  fullWidth
                  required
                  type="number"
                  id="outlined-required"
                  label="Quantos trabalham?"
                  value={getData(2)?.answer}
                  onChange={({target: {value}}) => {
                    saveInList([
                      {index: 2, question: 'Filhos: Quantos trabalham?', answer: value}
                    ])
                  }}
                />
                <TextField
                  required
                  fullWidth
                  type="number"
                  id="outlined-required"
                  label="Quantos não trabalham?"
                  value={getData(3)?.answer}
                  onChange={({target: {value}}) => {
                    saveInList([
                      {index: 3, question: 'Filhos: Quantos nāo trabalham?', answer: value}
                    ])
                  }}
                />
              </Box>
            )}
          </FormControl>

          <FormControl sx={{mt: 3, mb: 2}} fullWidth>
            <InputLabel id="demo-simple-select-label">Qual é o seu grau de instrução?</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Qual é o seu grau de instrução?"
              value={getData(4)?.answer || '-1'}
              onChange={({target: {value}}) => saveInList([{
                index: 4,
                question: 'Qual é o seu grau de instrução?', 
                answer: value
              }])}
            >
              <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
              <MenuItem value={'1° Ano ao 5° Ano'}>1° Ano ao 5° Ano</MenuItem>
              <MenuItem value={'6° Ano ao 9° Ano'}>6° Ano ao 9° Ano</MenuItem>
              <MenuItem value={'Ensino Médio'}>Ensino Médio</MenuItem>
              <MenuItem value={'Faculdade'}>Faculdade</MenuItem>
              <MenuItem value={'Não fiz escola'}>Não fiz escola</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{mt: 2}} component="fieldset" variant="standard">
            <FormLabel component="legend">Você possui comprovante de endereço? (conta de luz, água, gás, telefone no seu nome)</FormLabel>
            <VisibleInput inputTrue={
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Qual é o seu endereço completo?"
                value={getData(5)?.answer}
                onChange={({target: {value}}) => saveInList([{
                  index: 5,
                  question: 'Qual é o seu endereço completo?', 
                  answer: value
                }])}
              />
            } />         
          </FormControl>

          <FormControl fullWidth sx={{mt: 2}} component="fieldset" variant="standard">
            <FormLabel component="legend">Você possui conta bancária no seu nome?</FormLabel>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required"
                  label="Qual banco"
                  value={getData(6)?.answer}
                  onChange={({target: {value}}) => saveInList([{
                    index: 6,
                    question: 'conta bancária - Banco', 
                    answer: value
                  }])}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required"
                  label="Qual agência"
                  value={getData(7)?.answer}
                  onChange={({target: {value}}) => saveInList([{
                    index: 7,
                    question: 'conta bancária - Agência', 
                    answer: value
                  }])}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required"
                  label="Número da conta"
                  value={getData(8)?.answer}
                  onChange={({target: {value}}) => saveInList([{
                    index: 8,
                    question: 'conta bancária - Número da conta', 
                    answer: value
                  }])}
                />
              </Grid>
            </Grid>
          </FormControl>

          <FormControl fullWidth sx={{mt: 2}} component="fieldset" variant="standard">
            <FormLabel component="legend">Você mora de aluguel?</FormLabel>
            <VisibleInput inputTrue={
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Quanto paga de aluguel por mês?"
                value={getData(9)?.answer}
                onChange={({target: {value}}) => saveInList([{
                  index: 9,
                  question: 'conta bancária - Número da conta', 
                  answer: value
                }])}
              />
            } />         
          </FormControl>
        </Grid>

        <Grid item xl={6} lg={6} md={6} xs={12}>
          <FormControl fullWidth sx={{mt: 1, mb: 2}} component="fieldset" variant="standard">
            <FormLabel component="legend">Você já tem um negócio?</FormLabel>
            <VisibleInput
              inputTrue={
                <Box>
                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Que tipo de negócio você tem?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Que tipo de negócio você tem?"
                      value={getData(41)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([42])
                        saveInList([{
                          index: 41,
                          question: 'Que tipo de negócio você tem?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Comida'}>Comida</MenuItem>
                      <MenuItem value={'Artesanato'}>Artesanato</MenuItem>
                      <MenuItem value={'Roupas/Bijuterias'}>Roupas/Bijuterias</MenuItem>
                      <MenuItem value={'Beleza (Cabelo/Manicure)'}>Beleza (Cabelo/Manicure)</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(41)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva qual tipo de negócio."
                        value={getData(42)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 42,
                          question: 'Escreva qual tipo de negócio.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>
                  
                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Quando você começou?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Quando você começou?"
                      value={getData(11)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        saveInList([{
                          index: 11,
                          question: 'Quando você começou?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'faz 6 meses'}>faz 6 meses</MenuItem>
                      <MenuItem value={'faz entre 7 e 12 meses'}>faz entre 7 e 12 meses</MenuItem>
                      <MenuItem value={'faz entre 2 e 3 anos'}>faz entre 2 e 3 anos</MenuItem>
                      <MenuItem value={'faz entre 4 e 5 anos'}>faz entre 4 e 5 anos</MenuItem>
                      <MenuItem value={'faz 6 anos ou mais'}>faz 6 anos ou mais</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{mt: 5}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Como aprendeu o negócio que você faz?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Como aprendeu o negócio que você faz?"
                      value={getData(12)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([13])
                        saveInList([{
                          index: 12,
                          question: 'Como aprendeu o negócio que você faz?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Sozinha'}>Sozinha</MenuItem>
                      <MenuItem value={'Família (Mãe/Avó)'}>Família (Mãe/Avó)</MenuItem>
                      <MenuItem value={'Cursos'}>Cursos</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(12)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva como aprendeu o negócio que você faz."
                        value={getData(13)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 13,
                          question: 'Escreva como aprendeu o negócio que você faz.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>

                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Onde funciona o seu negócio?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Onde funciona o seu negócio?"
                      value={getData(14)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([15])
                        saveInList([{
                          index: 14,
                          question: 'Onde funciona o seu negócio?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Na sua casa'}>Na sua casa</MenuItem>
                      <MenuItem value={'Na casa de um parente'}>Na casa de um parente</MenuItem>
                      <MenuItem value={'Na casa de uma amiga'}>Na casa de uma amiga</MenuItem>
                      <MenuItem value={'Na rua'}>Na rua</MenuItem>
                      <MenuItem value={'Aluguel de espaço'}>Aluguel de espaço</MenuItem>
                      <MenuItem value={'Outros'}>outros</MenuItem>
                    </Select>
                    {getData(14)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva onde funciona o seu negócio."
                        value={getData(15)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 15,
                          question: 'Escreva onde funciona o seu negócio.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>

                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Onde você vende os seus produtos ou serviços?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Onde você vende os seus produtos ou serviços?"
                      value={getData(16)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([17])
                        saveInList([{
                          index: 16,
                          question: 'Onde você vende os seus produtos ou serviços?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Na sua casa'}>Na sua casa</MenuItem>
                      <MenuItem value={'Na feira'}>Na feira</MenuItem>
                      <MenuItem value={'Em lojas'}>Em lojas</MenuItem>
                      <MenuItem value={'De porta em porta'}>De porta em porta</MenuItem>
                      <MenuItem value={'Aluguel de espaço'}>Aluguel de espaço</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(16)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva onde você vende os seus produtos ou serviços."
                        value={getData(17)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 17,
                          question: 'Escreva onde você vende os seus produtos ou serviços.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>

                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Quantas pessoas trabalham com vocês?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Quantas pessoas trabalham com vocês?"
                      value={getData(18)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        saveInList([{
                          index: 18,
                          question: 'Quantas pessoas trabalham com vocês?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Trabalho sozinha'}>Trabalho sozinha</MenuItem>
                      <MenuItem value={'de 1 a 3 pessoas'}>de 1 a 3 pessoas</MenuItem>
                      <MenuItem value={'de 4 a 6 pessoas'}>de 4 a 6 pessoas</MenuItem>
                      <MenuItem value={'7 pessoas ou mais'}>7 pessoas ou mais</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{mt: 5}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Qual foi o seu faturamento no mês passado? (o que recebeu antes de pagar as contas)</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Qual foi o seu faturamento no mês passado? (o que recebeu antes de pagar as contas)"
                      value={getData(19)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        saveInList([{
                          index: 19,
                          question: 'Qual foi o seu faturamento no mês passado? (o que recebeu antes de pagar as contas)', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'até R$ 50,00 por mês'}>até R$ 50,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 100,00 por mês'}>até R$ 100,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 300,00 por mês'}>até R$ 300,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 600,00 por mês'}>até R$ 600,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 1.000,00 por mês'}>até R$ 1.000,00 por mês</MenuItem>
                      <MenuItem value={'mais de R$ 1.000,00 por mês'}>mais de R$ 1.000,00 por mês</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{mt: 5}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Quais foram as suas despesas com o negócio no mês passado?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Quais foram as suas despesas com o negócio no mês passado?"
                      value={getData(20)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        saveInList([{
                          index: 20,
                          question: 'Quais foram as suas despesas com o negócio no mês passado?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'até R$ 50,00 por mês'}>até R$ 50,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 100,00 por mês'}>até R$ 100,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 300,00 por mês'}>até R$ 300,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 600,00 por mês'}>até R$ 600,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 1.000,00 por mês'}>até R$ 1.000,00 por mês</MenuItem>
                      <MenuItem value={'mais de R$ 1.000,00 por mês'}>mais de R$ 1.000,00 por mês</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{mt: 5}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Qual é a maior dificuldade que você tem no seu negócio?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Qual é a maior dificuldade que você tem no seu negócio?"
                      value={getData(21)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([22])
                        saveInList([{
                          index: 21,
                          question: 'Qual é a maior dificuldade que você tem no seu negócio?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Vendas baixas'}>Vendas baixas</MenuItem>
                      <MenuItem value={'Como fazer as contas'}>Como fazer as contas</MenuItem>
                      <MenuItem value={'Ter lucro/Sobrar dinheiro'}>Ter lucro/Sobrar dinheiro</MenuItem>
                      <MenuItem value={'Preço do material'}>Preço do material</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(21)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva a maior dificuldade que você tem no seu negócio."
                        value={getData(22)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 22,
                          question: 'Escreva a maior dificuldade que você tem no seu negócio.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>

                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">No que você pretende gastar os R$ 2.000,00 que poderá receber do Fundo Dona de Mim?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="No que você pretende gastar os R$ 2.000,00 que poderá receber do Fundo Dona de Mim?"
                      value={getData(23)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([24])
                        saveInList([{
                          index: 23,
                          question: 'No que você pretende gastar os R$ 2.000,00 que poderá receber do Fundo Dona de Mim?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Pagar as despesas de casa'}>Pagar as despesas de casa</MenuItem>
                      <MenuItem value={'Compra de matéria prima'}>Compra de matéria prima</MenuItem>
                      <MenuItem value={'Contratar funcionários'}>Contratar funcionários</MenuItem>
                      <MenuItem value={'Compra de equipamentos'}>Compra de equipamentos</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(23)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva como você pretende gastar os R$ 2.000,00."
                        value={getData(24)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 24,
                          question: 'Escreva como você pretende gastar os R$ 2.000,00.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>

                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Quais foram as suas despesas com o negócio no mês passado?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Quais foram as suas despesas com o negócio no mês passado?"
                      value={getData(25)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([26])
                        saveInList([{
                          index: 25,
                          question: 'Quais foram as suas despesas com o negócio no mês passado?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'até R$ 50,00 por mês'}>até R$ 50,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 100,00 por mês'}>até R$ 100,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 300,00 por mês'}>até R$ 300,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 600,00 por mês'}>até R$ 600,00 por mês</MenuItem>
                      <MenuItem value={'até R$ 1.000,00 por mês'}>até R$ 1.000,00 por mês</MenuItem>
                      <MenuItem value={'mais de R$ 1.000,00 por mês'}>mais de R$ 1.000,00 por mês</MenuItem>
                    </Select>
                    {getData(25)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva quais foram as suas despesas com o negócio no mês passado."
                        value={getData(26)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 26,
                          question: 'Escreva quais foram as suas despesas com o negócio no mês passado.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>
                </Box>
              }
              inputFalse={
                <Box>
                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Qual é o negócio que você quer montar?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Qual é o negócio que você quer montar?"
                      value={getData(27)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([28])
                        saveInList([{
                          index: 27,
                          question: 'Qual é o negócio que você quer montar?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Comida'}>Comida</MenuItem>
                      <MenuItem value={'Artesanato'}>Artesanato</MenuItem>
                      <MenuItem value={'Roupas/Bijuterias'}>Roupas/Bijuterias</MenuItem>
                      <MenuItem value={'Beleza (Cabelo/Manicure)'}>Beleza (Cabelo/Manicure)</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(27)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva quais foram as suas despesas com o negócio no mês passado."
                        value={getData(28)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 28,
                          question: 'Escreva quais foram as suas despesas com o negócio no mês passado.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>
                  
                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Como aprendeu o negócio que você quer fazer?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Como aprendeu o negócio que você quer fazer?"
                      value={getData(29)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([30])
                        saveInList([{
                          index: 29,
                          question: 'Como aprendeu o negócio que você quer fazer?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Não aprendi'}>Não aprendi</MenuItem>
                      <MenuItem value={'Sozinha'}>Sozinha</MenuItem>
                      <MenuItem value={'Família (Mãe/Avó)'}>Família (Mãe/Avó)</MenuItem>
                      <MenuItem value={'Amigas'}>Amigas</MenuItem>
                      <MenuItem value={'Cursos'}>Cursos</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(29)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva como aprendeu o negócio que você quer fazer."
                        value={getData(30)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 30,
                          question: 'Escreva como aprendeu o negócio que você quer fazer.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>

                  <FormControl sx={{mt: 5}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Onde você quer montá-lo?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Onde você quer montá-lo?"
                      value={getData(31)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([32])
                        saveInList([{
                          index: 31,
                          question: 'Onde você quer montá-lo?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Na sua casa'}>Na sua casa</MenuItem>
                      <MenuItem value={'Na casa de um parente'}>Na casa de um parente</MenuItem>
                      <MenuItem value={'Na casa de uma amiga'}>Na casa de uma amiga</MenuItem>
                      <MenuItem value={'Na rua'}>Na rua</MenuItem>
                      <MenuItem value={'Aluguel de espaço'}>Aluguel de espaço</MenuItem>
                      <MenuItem value={'Outros'}>outros</MenuItem>
                    </Select>
                    {getData(31)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva onde você quer montá-lo."
                        value={getData(32)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 32,
                          question: 'Escreva onde você quer montá-lo.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>

                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Onde você quer vender os seus produtos ou serviços?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Onde você quer vender os seus produtos ou serviços?"
                      value={getData(33)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([34])
                        saveInList([{
                          index: 33,
                          question: 'Onde você quer vender os seus produtos ou serviços?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Na sua casa'}>Na sua casa</MenuItem>
                      <MenuItem value={'Na feira'}>Na feira</MenuItem>
                      <MenuItem value={'Em lojas'}>Em lojas</MenuItem>
                      <MenuItem value={'De porta em porta'}>De porta em porta</MenuItem>
                      <MenuItem value={'Aluguel de espaço'}>Aluguel de espaço</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(33)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva onde você quer vender os seus produtos ou serviços."
                        value={getData(34)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 34,
                          question: 'Escreva onde você quer vender os seus produtos ou serviços.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>

                  <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Quantas pessoas você vai precisar que trabalhe junto com você?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Quantas pessoas você vai precisar que trabalhe junto com você?"
                      value={getData(35)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        saveInList([{
                          index: 35,
                          question: 'Quantas pessoas você vai precisar que trabalhe junto com você?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'vou trabalhar sozinha'}>Trabalho sozinha</MenuItem>
                      <MenuItem value={'de 1 a 3 pessoas'}>de 1 a 3 pessoas</MenuItem>
                      <MenuItem value={'de 4 a 6 pessoas'}>de 4 a 6 pessoas</MenuItem>
                      <MenuItem value={'7 pessoas ou mais'}>7 pessoas ou mais</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{mt: 5}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Qual é o valor que você vai precisar para começar o seu negócio?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Qual é o valor que você vai precisar para começar o seu negócio?"
                      value={getData(36)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        saveInList([{
                          index: 36,
                          question: 'Qual é o valor que você vai precisar para começar o seu negócio?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'até R$ 500,00'}>até R$ 500,00</MenuItem>
                      <MenuItem value={'até R$ 1.000,00'}>até R$ 1.000,00</MenuItem>
                      <MenuItem value={'até R$ 2.000,00'}>até R$ 2.000,00</MenuItem>
                      <MenuItem value={'mais de R$ 2.000,00'}>mais de R$ 2.000,00</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{mt: 5}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Qual é a sua maior preocupação em ter seu próprio negócio?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Qual é a sua maior preocupação em ter seu próprio negócio?"
                      value={getData(37)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([38])
                        saveInList([{
                          index: 37,
                          question: 'Qual é a sua maior preocupação em ter seu próprio negócio?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Assumir dívidas'}>Assumir dívidas</MenuItem>
                      <MenuItem value={'Vendas baixas'}>Vendas baixas</MenuItem>
                      <MenuItem value={'Fazer as contas'}>Fazer as contas</MenuItem>
                      <MenuItem value={'Ter prejuízo'}>Ter prejuízo</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(37)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva a sua maior preocupação em ter seu próprio negócio."
                        value={getData(38)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 38,
                          question: 'Escreva a sua maior preocupação em ter seu próprio negócio.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>

                  <FormControl sx={{mt: 5}} fullWidth>
                    <InputLabel id="demo-simple-select-label">No que você pretende gastar os R$ 2.000,00 que poderá receber do Fundo Dona de Mim?</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="No que você pretende gastar os R$ 2.000,00 que poderá receber do Fundo Dona de Mim?"
                      value={getData(39)?.answer || '-1'}
                      onChange={({target: {value}}) => {
                        value !== 'Outros' && deleteInList([40])
                        saveInList([{
                          index: 39,
                          question: 'No que você pretende gastar os R$ 2.000,00 que poderá receber do Fundo Dona de Mim?', 
                          answer: value
                        }])
                      }}
                    >
                      <MenuItem value={'-1'} disabled={true}>Selecionar</MenuItem>
                      <MenuItem value={'Assumir dívidas'}>Assumir dívidas</MenuItem>
                      <MenuItem value={'Vendas baixas'}>Vendas baixas</MenuItem>
                      <MenuItem value={'Fazer as contas'}>Fazer as contas</MenuItem>
                      <MenuItem value={'Ter prejuízo'}>Ter prejuízo</MenuItem>
                      <MenuItem value={'Outros'}>Outros</MenuItem>
                    </Select>
                    {getData(39)?.answer === 'Outros' && (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Escreva no que você pretende gastar os R$ 2.000,00 que poderá receber do Fundo Dona de Mim."
                        value={getData(40)?.answer}
                        onChange={({target: {value}}) => saveInList([{
                          index: 40,
                          question: 'Escreva no que você pretende gastar os R$ 2.000,00 que poderá receber do Fundo Dona de Mim.', 
                          answer: value
                        }])}
                      />
                    )}
                  </FormControl>
                </Box>
              }
            />
          </FormControl>

          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Como você acredita que o Fundo Dona de Mim poderá te ajudar?"
            value={getData(41)?.answer}
            onChange={({target: {value}}) => saveInList([{
              index: 41,
              question: 'Como você acredita que o Fundo Dona de Mim poderá te ajudar?', 
              answer: value
            }])}
          />

          <Typography variant='caption'>
            Ao clicar em "Próximo" você concorda com nossos <Link href='https://www.n2app.com.br/termo-de-uso-e-privacidade/' target="_blank">Termos de uso e Privacidade</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

const VisibleInput = ({inputTrue, inputFalse}: any) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <RadioGroup sx={{alignItems: 'center', display: 'flex'}} row name="row-radio-buttons-group">
        <FormControlLabel
          value={false}
          checked={!visible}
          control={<Radio />}
          label="Não"
          onChange={() => setVisible(false)}
        />
        <FormControlLabel
          value={true}
          checked={visible}
          control={<Radio />}
          label="Sim"
          onChange={(e, value) => setVisible(true)}
        />
      </RadioGroup>

      {visible ? inputTrue : inputFalse}
    </>
  )
}
