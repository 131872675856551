import React, {createContext, useContext, useMemo, useState, useEffect} from 'react';
import { createTheme, ThemeOptions, Theme, ThemeProvider as ThemeMUIProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';

interface IContext {
  themeScheme: string | 'dark' | 'light' | 'system';
  setThemeScheme(mode: 'dark' | 'light' | 'system'): void;
  theme: Theme;
  changeThemeScheme(mode: 'dark' | 'light' | 'system'): void,
  themeMode?: string | 'dark' | 'light',
  backgroundColor?: any,
  isNoMobile: boolean,
}

const Context = createContext({} as IContext);

export default function ThemeProvider({children}: {children: React.ReactNode}) {
  const [themeScheme, setThemeScheme] = useState<string | 'dark' | 'light' | 'system'>('light');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const systemMode = 'light';

  const changeThemeScheme = (mode: string) => {
    localStorage.setItem('themeScheme', mode);
    setThemeScheme(mode);
  }

  useEffect(() => {
    const themeSchemeMode = localStorage.getItem('themeScheme');
    setThemeScheme(themeSchemeMode || systemMode);
  }, [prefersDarkMode])

  const theme = createTheme({
    components: {
      MuiLink: {
        defaultProps: {
          underline: "none"
        }
      },
      MuiButton: {
        defaultProps: {
          size: 'medium'
        }
      },
      MuiSlider: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiRadio: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiCheckbox: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          size: 'small',
          sx: {
            fontSize: 16
          },
        },
      },
      MuiFormLabel: {
        defaultProps: {
          sx: {
            fontSize: 16
          }
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
        }
      },
      
    },
    palette: {
      primary: {
        main: '#693fb9',
      },
      mode: themeScheme === 'system' ? systemMode : themeScheme,
    },
  } as ThemeOptions);

  const themeMode = themeScheme === 'system' ? systemMode : themeScheme;
  const backgroundColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : '#121212';
  const isNoMobile = useMediaQuery(theme.breakpoints.up("md"));

  const value = useMemo(() => ({
    theme,
    themeScheme,
    setThemeScheme,
    changeThemeScheme,
    themeMode,
    backgroundColor,
    isNoMobile
  }), [theme, themeScheme, isNoMobile]);

  return (
    <ThemeMUIProvider theme={theme}>
      <CssBaseline />
      <Context.Provider value={value}>{children}</Context.Provider>
    </ThemeMUIProvider>
  );
}

export function useTheme() {
  const context: IContext = useContext(Context);
  return context;
}